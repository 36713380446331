import { useState } from "react";

const Avatar = () => {
    const [imageLoaded, setImageLoaded] = useState(false);

    const generateAvatarUrl = () => {
        const baseUrl = 'https://xsgames.co/randomusers/avatar.php?g=';
        const gender = Math.random() < 0.5 ? 'male' : 'female';
        return `${baseUrl}${gender}`;
    };

    const handleImageLoad = () => {
        setImageLoaded(true);
    };

    return (
        <div className="w-10 h-10 rounded-[200px] justify-end items-center flex">
            {!imageLoaded && (
                <div className="animate-pulse w-10 h-10 bg-gray-300 rounded-full" />
            )}
            <img
                className={`rounded-full ${!imageLoaded ? 'hidden' : ''}`}
                src={generateAvatarUrl()}
                alt="User Avatar"
                onLoad={handleImageLoad}
            />
        </div>
    );
}

const ChatMessageInformation = ({ name, time = '', isCurrentUser }) => {
    return (
        <div className={`flex place-content-between w-full items-center ${isCurrentUser ? 'flex-row-reverse' : ''}`}>
            <p className="text-slate-700 dark:text-slate-200 text-sm font-medium">{name}</p>
            <p className="text-gray-500 dark:text-gray-400 text-xs font-normal">{time}</p>
        </div >
    );
}

const Message = ({ styles, text }) => {
    return (
        <div className={`${styles} p-2.5 bg-gray-200 dark:bg-gray-800 rounded-bl-lg rounded-br-lg shadow justify-start items-center gap-1 inline-flex`}>
            {text ? <div className="grow shrink basis-0 text-gray-900 dark:text-gray-200 text-base font-normal leading-normal">{text}</div>
                : [...Array(3)].map((_, index) => (
                    <div
                        key={index}
                        className="dot bg-gray-900 dark:bg-gray-200"
                        style={{ '--index': index }}
                    />
                ))}
        </div>
    );
}

export const ChatMessage = ({ data, isCurrentUser }) => {
    return (
        <div className={`flex m-4 items-end max-w-[360px] w-fit gap-3 ${isCurrentUser ? 'ml-auto flex-row-reverse' : 'mr-auto'
            }`}>
            {/* <Avatar /> */}
            <div className="grow shrink basis-0 flex-col justify-start items-start gap-1.5 inline-flex">
                <ChatMessageInformation name={data.name} time={data.time} isCurrentUser={isCurrentUser} />
                <Message styles={`${isCurrentUser ? 'bg-gray-300 dark:bg-slate-900 rounded-tl-lg' : 'rounded-tr-lg'}`} text={data.message}/>
            </div>
        </div>
    );
}