import React, { useEffect, useState } from 'react';
import Layout from './components/Layout';
import Loader from './pages/LoadingPage';
import NotificationProvider from './features/Notifications';
import { LangProvider } from './components/Language';
import ÆttirRouter from './features/Router';
import { initGA, logPageView } from './features/Analytics';

const App = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    initGA(); // Initialize Google Analytics
    logPageView(); // Log the initial pageview
  }, []);

  // Render the regular content
  return (
    <Layout className={`${isLoading ? 'overflow-hidden' : 'overflow-auto'}`}>
      <NotificationProvider>
        <LangProvider setIsLoading={setIsLoading}>
          <Loader isLoading={isLoading}>
            <ÆttirRouter />
          </Loader>
        </LangProvider>
      </NotificationProvider>
    </Layout>
  );
}

export default App;
