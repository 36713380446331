import React from "react";
import FaqSection from "../components/Faq";
import HeroSection from "../components/Hero";
import ContactSection from "../components/Contact";
import Footer from "../components/Footer";

const LandingPage = () => {
    return (
        <div className="pt-24">
            <HeroSection />
            <FaqSection />
            <ContactSection />
            <Footer />
        </div>
    );
};


export default LandingPage;