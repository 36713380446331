import React, { useState } from "react";

const ContactForm = () => {
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        message: "",
    });

    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
        // Clear the error when user starts typing
        setErrors({
            ...errors,
            [name]: "",
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // Simple validation logic, you can extend it as needed
        const newErrors = {};
        if (!formData.name.trim()) {
            newErrors.name = "Name is required";
        }
        if (!formData.email.trim()) {
            newErrors.email = "Email is required";
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            newErrors.email = "Invalid email address";
        }
        if (!formData.message.trim()) {
            newErrors.message = "Message is required";
        }

        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }

        // Handle form submission logic here
        // For now, just log the form data
        console.log("Form Data:", formData);
    };

    return (
        <form onSubmit={handleSubmit} className="mt-14 grid grid-cols-1 sm:grid-cols-2 gap-x-5 gap-y-4">
            <div>
                <label htmlFor="name" className="text-base font-medium text-gray-900 dark:text-gray-100">
                    Your name
                </label>
                <div className="mt-2.5 relative">
                    <input
                        type="text"
                        id="name"
                        name="name"
                        placeholder="Enter your full name"
                        value={formData.name}
                        onChange={handleChange}
                        className={`block w-full px-4 py-4 text-gray-900 dark:text-gray-100 placeholder-gray-500 transition-all duration-200 bg-transparent border ${errors.name ? "border-red-500" : "border-gray-200"
                            } rounded-md focus:outline-none focus:border-blue-600 caret-blue-600`}
                    />
                    {errors.name && <p className="text-sm text-red-500 mt-2">{errors.name}</p>}
                </div>
            </div>

            <div>
                <label htmlFor="email" className="text-base font-medium text-gray-900 dark:text-gray-100">
                    Email address
                </label>
                <div className="mt-2.5 relative">
                    <input
                        type="email"
                        id="email"
                        name="email"
                        placeholder="Enter your email address"
                        value={formData.email}
                        onChange={handleChange}
                        className={`block w-full px-4 py-4 text-gray-900 dark:text-gray-100 placeholder-gray-500 transition-all duration-200 bg-transparent border ${errors.email ? "border-red-500" : "border-gray-200"
                            } rounded-md focus:outline-none focus:border-blue-600 caret-blue-600`}
                    />
                    {errors.email && <p className="text-sm text-red-500 mt-2">{errors.email}</p>}
                </div>
            </div>

            <div className="sm:col-span-2">
                <label htmlFor="message" className="text-base font-medium text-gray-900 dark:text-gray-100">
                    Message
                </label>
                <div className="mt-2.5 relative">
                    <textarea
                        id="message"
                        name="message"
                        placeholder="Message"
                        value={formData.message}
                        onChange={handleChange}
                        className={`block w-full px-4 py-4 text-gray-900 dark:text-gray-100 placeholder-gray-500 transition-all duration-200 bg-transparent border ${errors.message ? "border-red-500" : "border-gray-200"
                            } rounded-md resize-y focus:outline-none focus:border-blue-600 caret-blue-600`}
                        rows="4"
                    ></textarea>
                    {errors.message && <p className="text-sm text-red-500 mt-2">{errors.message}</p>}
                </div>
            </div>

            <div className="sm:col-span-2">
                <button
                    type="submit"
                    className="w-full px-4 py-4 mt-2 text-base font-semibold text-white transition-all duration-200 bg-blue-300 dark:bg-gray-800 border border-transparent rounded-md focus:outline-none hover:bg-blue-500 dark:hover:bg-gray-700 focus:bg-blue-500 dark:focus:bg-gray-700"
                >
                    Send
                </button>
            </div>
        </form>
    );
}

const ContactSection = () => {
    return (
        <section className="py-10 sm:py-16 lg:py-24">
            <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
                <div className="max-w-2xl mx-auto text-center">
                    <h2 className="text-center text-gray-700 dark:text-zinc-100 text-4xl font-semibold leading-tight">Contact us</h2>
                    <p className="max-w-xl mx-auto mt-4 text-gray-500 dark:text-gray-400 text-xl font-normal leading-relaxed">Did you spot a bug or have an issue with something?</p>
                </div>

                <div className="max-w-5xl mx-auto mt-12 sm:mt-16">
                    <div className="mt-6 overflow-hidden rounded-xl">
                        <div className="px-6 py-12 sm:p-12">
                            <h3 className="text-3xl font-semibold text-center text-gray-900 dark:text-gray-100">Send us a message</h3>
                            <ContactForm />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ContactSection;
