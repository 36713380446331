import React, { useEffect, useState } from "react";
import { DefaultButton } from "../components/Buttons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

const ErrorPage = ({ code }) => {
    const [codeCounter, setCodeCounter] = useState(0);
    const [intervalTime, setIntervalTime] = useState(2);
    const navigate = useNavigate();

    useEffect(() => {
        const interval = setInterval(() => {
            if (codeCounter < code) {
                setCodeCounter((prevCounter) => prevCounter + 1);
            } else {
                clearInterval(interval);
            }
        }, intervalTime);

        return () => clearInterval(interval); // Cleanup the interval on component unmount
    }, [codeCounter, code, intervalTime]);

    useEffect(() => {
        let diff = code - codeCounter;
        if (diff < 10) {
            setIntervalTime(100);
        } else if (diff < 30) {
            setIntervalTime(40);
        } else if (diff < 50) {
            setIntervalTime(20);
        }
    }, [code, codeCounter]);

    return (
        <div className="flex items-center justify-center text-center h-screen w-full">
            <div>
                <h1 data-h1={codeCounter} className="h1-after-animation text-[15rem] font-bold relative -mt-8 p-0">
                    <span
                        className="h1-after-animation absolute top-0 left-0 right-0 color-transparent bg-clip-text text-transparent bg-gradient-to-br from-blue-400 via-purple-400 to-red-500 bg-size-400% text-shadow"

                    ></span>
                    {codeCounter}
                </h1>
                <p className="relative text-gray-300 max-w-[500px] text-5xl font-bold leading-10 relative">
                    NOT FOUND
                </p>
                <p className="relative text-gray-300 my-8 max-w-[400px] relative">
                    Oops! You've ventured into the unknown.
                    It seems the page you're looking for is playing hide and seek.
                </p>
                <DefaultButton className="mx-auto max-w-fit flex gap-3 items-center" onClick={() => navigate('/')}>
                    <FontAwesomeIcon icon={faArrowLeft} />
                    Go back
                </DefaultButton>
            </div>
        </div>
    );
}

export default ErrorPage;