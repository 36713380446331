import React, { useEffect, useState, useCallback } from 'react';
import  { useData } from '../components/Language';
import { NotifyMeForm } from '../components/Forms';
import Footer from '../components/Footer';
import { PageLayout } from '../components/Layout';

const CountdownItem = ({ value, type }) => {
    return (
        <div className="lg:mb-4 dark:bg-gray-800 p-4 rounded-lg text-center border dark:border-none border-gray-200">
            <div className="countdown-value text-4xl font-bold text-purple-500 dark:text-gray-200">{value}</div>
            <div className="text-gray-700 dark:text-gray-300">{type}</div>
        </div>
    );
}

const CountdownGroup = ({ children }) => {
    return (
        <div className='flex gap-4 flex-wrap lg:block justify-center'>
            {children}
        </div>
    );
}

const CountdownContainer = ({ timestamp }) => {
    const { getData } = useData();

    const calculateCountdown = useCallback(() => {
        const now = new Date();
        const targetDate = timestamp ? new Date(timestamp) : now;

        const timeDifference = targetDate - now;
        const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
        const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

        return { days, hours, minutes, seconds };
    }, [timestamp]);

    const [countdown, setCountdown] = useState(calculateCountdown());

    useEffect(() => {
        const interval = setInterval(() => {
            setCountdown(calculateCountdown());
        }, 1000);

        return () => clearInterval(interval);
    }, [calculateCountdown]);

    return (
        <div className="py-10">
                    <div className="flex flex-wrap justify-center lg:space-x-0 gap-4 lg:gap-0 lg:pl-4 lg:flex-col sm:mt-8 mx-4 my-4">
                        <CountdownGroup>
                            <CountdownItem value={countdown.days} type={getData('counter.days')} />
                            <CountdownItem value={countdown.hours} type={getData('counter.hours')} />
                        </CountdownGroup>
                        <CountdownGroup>
                            <CountdownItem value={countdown.minutes} type={getData('counter.minutes')} />
                            <CountdownItem value={countdown.seconds} type={getData('counter.seconds')} />
                        </CountdownGroup>
                    </div>
        </div>
    );
};

const ComingSoon = ({ timestamp }) => {
    const { getData } = useData();

    const transformText = (text) => {
        const transformedText = text.replace(/ \*\*/g, " <span className='italic'>").replace(/\*\* /g, "</span> ");
        return { __html: transformedText };
    };

    return (
        <PageLayout >
            <div className="relative overflow-hidden">
                <div className="pt-10 pb-14 sm:pt-16 lg:overflmow-hidden lg:pt-24 lg:pb-24">
                    <div className="mx-auto max-w-5xl lg:px-8">
                        <div className="lg:grid lg:grid-cols-2 items-center lg:gap-8">
                            <div className="mx-auto max-w-md px-4 text-center sm:max-w-2xl sm:px-6 lg:flex lg:items-center lg:px-0 lg:text-left">
                                <div className="lg:py-24">
                                    <h1 className="mt-4 text-4xl font-bold tracking-tight text-black sm:mt-5 sm:text-6xl lg:mt-6 xl:text-6xl">
                                        <span className="block text-purple-500 dark:text-blue-400">{getData('coming_soon.headline')}</span>
                                        <span className="block text-gray-900 dark:text-gray-100">{getData('coming_soon.title')}</span>
                                    </h1>

                                    <p className="mt-3 text-base text-gray-400 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl" dangerouslySetInnerHTML={transformText(getData('coming_soon.context'))}></p>
                                    <div className="mt-10 sm:mt-12">
                                        <NotifyMeForm />
                                    </div>
                                </div>
                            </div>
                            <CountdownContainer timestamp={timestamp} />
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </PageLayout>
    );
};

export default ComingSoon;