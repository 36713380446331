import React from "react";
import FaqSection from "../components/Faq";
import Footer from "../components/Footer";
import { PageLayout } from "../components/Layout";
import AboutSection from "../components/About";

const AboutPage = () => {
    return(
        <PageLayout>
            <AboutSection />
            <FaqSection />
            <Footer />
        </PageLayout>
    );
}

export default AboutPage;