import React, { useEffect, useState } from "react";
import { useData } from "../components/Language";
import { PageLayout } from "../components/Layout";
import Footer from "../components/Footer";

const TermsAndPrivacyPage = ({ type }) => {
    const { getData } = useData();

    return (
        <PageLayout>
            <div className="max-w-[600px] mx-auto p-6 bg-white shadow-md rounded-md my-8">
                <h2 className="text-2xl font-bold mb-4">{getData(`${type}.title`)}</h2>
                <ul>
                    {
                        getData(`${type}.content`).map((item, index) => (
                            <TAPItem key={index} index={index} item={item} />
                        ))
                    }
                </ul>
            </div>
            <Footer />
        </PageLayout>
    );
};

const TAPItem = ({ index, item }) => {
    const [count, setCount] = useState(index);

    useEffect(() => {
        if (index < 10) {
            setCount(`0${index}`);
        }
    }, [index, setCount]);

    return (
        <li className="mb-4">
            {typeof item === 'string' ? (
                <p className="text-gray-800">{item}</p>
            ) : (
                <div>
                    <h3 className="text-lg font-semibold mb-2">{count}. {item.title}</h3>
                    <ul className="list-disc list-inside w-full">
                        {item.content.split(/\.+/).map((text, i) => {
                            return text 
                                ? <li key={i} className="w-full text-gray-700">{text}.</li>
                                : null;
                        })}
                    </ul>
                </div>
            )}
        </li>
    );
}

export default TermsAndPrivacyPage;