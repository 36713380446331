import React from "react";
import socials from '../assets/data/socials.json';
import { useData } from "./Language";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram, faXTwitter } from '@fortawesome/free-brands-svg-icons';

const socialsWithIcon = {
    "X": <FontAwesomeIcon icon={faXTwitter} size="lg" />,
    "Instagram": <FontAwesomeIcon icon={faInstagram} size="lg" />
}

const Footer = () => {
    const { getData } = useData();

    return (
        <footer>
            <div className="mx-auto max-w-7xl overflow-hidden py-12 px-4 sm:px-6 lg:px-8">
                <nav className="-mx-5 -my-2 flex flex-wrap justify-center" aria-label="Footer">
                    <div className="px-5 py-2"><a href="/about" className="text-base text-gray-500 hover:text-gray-900 dark:text-gray-100 dark:hover:text-gray-400">{getData('links.about')}</a></div>
                    <div className="px-5 py-2"><a href="/terms" className="text-base text-gray-500 hover:text-gray-900 dark:text-gray-100 dark:hover:text-gray-400">{getData('links.terms')}</a></div>
                    <div className="px-5 py-2"><a href="/privacy" className="text-base text-gray-500 hover:text-gray-900 dark:text-gray-100 dark:hover:text-gray-400">{getData('links.privacy')}</a></div>
                </nav>
                <div className="mt-8 flex justify-center space-x-6">
                    {Object.keys(socials).map((social, index) => {
                        return (
                            <a key={index}
                                href={socials[social]}
                                title={social}
                                className="text-gray-400 hover:text-gray-500"
                            >
                                {socialsWithIcon[social]}
                            </a>
                        );
                    })}
                </div>
                <p className="mt-8 text-center text-base text-gray-400 dark:text-gray-300">{getData('copyright', {YEAR:new Date().getFullYear() })}</p>
            </div>
        </footer>
    );
}

export default Footer;