import React, {
    useState,
    useEffect
} from "react";

// import { ReactComponent as ImageIcon } from '../assets/icons/image.svg';
// import { ReactComponent as AddIcon } from '../assets/icons/add.svg';
// import { ReactComponent as ChatsIcon } from '../assets/icons/chats.svg';
// import { ReactComponent as EmojiIcon } from '../assets/icons/chats.svg';
import { ReactComponent as PaperPlaneIcon } from '../assets/icons/paper-plane.svg';

const IconButton = ({ disabled = false, icon: Icon, onClick, title }) => {
    return (
        <button
            disabled={disabled}
            type="button"
            className="flex shrink-0 focus:outline-none mx-2 disabled:text-gray-500 text-teal-500 hover:text-slate-800 w-6 h-6"
            onClick={onClick}
            title={title}
        >
            <Icon />
        </button>
    );
};

const ChatFooter = ({ socket, clearMessages }) => {
    const [message, setMessage] = useState("");
    const [skipUser, setSkipUser] = useState(null);
    const [skipButtonText, setSkipButtonText] = useState(null);
    const [isInRoom, setIsInRoom] = useState(false);
    const [isInit, setIsInit] = useState(false);
    const [excludedRoom, setExludedRoom] = useState(null);
    const [currentRoom, setCurrentRoom] = useState(null);
    const [isOtherUserConnected, setIsOtherUserConnected] = useState(false);
    const [disconnectedMessage, setDisconnectedMessage] = useState("");
    const [autoContinue, setAutoContinue] = useState(false);

    const joinRoom = () => {
        if (socket) {
            socket.emit("join_room", excludedRoom);
            setIsInRoom(true);
        }
    };

    const leaveRoom = () => {
        if (socket) {
            socket.emit("leave_room");
            setAutoContinue(false);
            setIsInRoom(false);
        }
    };

    const sendMessage = () => {
        if (socket && isInRoom && message.trim() !== "") {
            socket.emit("send_message", { room: currentRoom, message });
            // You might want to update the UI to display the sent message
            setMessage("");
        }
    };

    const skipButtonHandler = () => {
        setSkipUser((prevSkipUser) => {
            let newText;
            let newValue;

            switch (prevSkipUser) {
                case 0:
                    newText = "Stop Chatting";
                    newValue = 1;
                    joinRoom();
                    break;
                case 1:
                    newText = "Are you sure?";
                    newValue = 2;
                    break;
                default:
                    newText = "Start Chatting";
                    setDisconnectedMessage("You have disconnected.");
                    newValue = 0;
                    leaveRoom();
                    break;
            }

            setSkipButtonText(newText);
            return newValue;
        });
    };

    const startTyping = () => {
        if (socket && isInRoom) {
            socket.emit("start_typing", currentRoom);
        }
    };

    const stopTyping = () => {
        if (socket && isInRoom) {
            socket.emit("stop_typing", currentRoom);
        }
    };

    useEffect(() => {
        if (!isInit) {
            if (!isInRoom) {
                setSkipUser(0);
                setSkipButtonText("Start Chatting");
            } else {
                setSkipUser(2);
                setSkipButtonText("Stop Chatting");
            }
            setIsInit(true);
        }

        socket.on("join_room", (currentRoom, occupancy) => {
            // Now you have the excluded room, you can use it when joining a new room
            setCurrentRoom(currentRoom);
            clearMessages();

            if (occupancy === 2) {
                setIsOtherUserConnected(true);
            }
        });

        socket.on("left_room", (prevRoom) => {
            setExludedRoom(prevRoom);
            setCurrentRoom(null);
            setIsOtherUserConnected(false);
        });

        // Listen for the "user_joined" event
        socket.on("user_joined", ({ userId, room }) => {
            // Check if the joined user is not the current user
            if (userId !== socket.id) {
                setIsOtherUserConnected(true);
                // You can perform any other actions or update UI accordingly
            }
        });

        socket.on("user_left", ({ userId, room }) => {
            if (userId !== socket.id) {
                setIsOtherUserConnected(false);
                setDisconnectedMessage("Stranger has disconnected...");
        
                if (autoContinue) {
                    socket.emit("next_room");
                    setDisconnectedMessage("Stranger has disconnected... We are trying to find a new one.");
                } else {
                    socket.emit("leave_room");
                    setIsInRoom(false);
                    setSkipUser(0);
                    setSkipButtonText("Start Chatting");
                    // You can perform any other actions or update UI accordingly
                }
            }
        });
        // Clean up the event listener when the component unmounts
        return () => {
            socket.off("user_joined");
            socket.off("left_room");
            socket.off("join_room");
            socket.off("user_left");
        };
    }, [socket, isInit, isInRoom, isOtherUserConnected, setIsOtherUserConnected, autoContinue, clearMessages]);

    return (
        <div className="chat-footer max-w-[700px] w-full flex-none bg-zinc-100 dark:bg-zinc-950">
            <p className={`dark:text-gray-100 italic pl-5 transition-opacity delay-[1s] duration-500 ease-in-out ${isOtherUserConnected ? 'opacity-0' : 'opacity-100'}`}>
                {isOtherUserConnected ? 'You are connected with a stranger - Happy Chatting!' :
                    skipUser !== 0 ? 'We are trying to find a new stranger for you!' : disconnectedMessage}
            </p>
            <div className="flex flex-row items-center p-4">
                {/* <IconButton disabled={!isOtherUserConnected} icon={AddIcon} onClick={() => { }} title="Add" />
                <IconButton disabled={!isOtherUserConnected} icon={ImageIcon} onClick={() => { }} title="Image" />
                <IconButton disabled={!isOtherUserConnected} icon={ChatsIcon} onClick={() => { }} title="Chats" /> */}

                <div className="relative flex-grow">
                    <label>
                        <input
                            disabled={!isOtherUserConnected}
                            value={message}
                            onKeyDown={(e) => e.key === 'Enter' && sendMessage()}
                            onChange={(e) => {
                                setMessage(e.target.value);
                                e.target.value ? startTyping() : stopTyping();
                            }}        
                            className="disabled:border-gray-400 rounded-full border border-teal-400 focus:outline-none focusf:ring focus:ring-teal-300 py-2 pl-3 pr-10 w-full bg-transparent text-slate-600 dark:text-gray-100 focus:shadow-md transition duration-300 ease-in"
                            type="text"
                            placeholder="Aa"
                        />
                        {/* <button disabled={!isOtherUserConnected} type="button" className="disabled:text-gray-500 absolute top-0 right-0 mt-2 mr-3 flex shrink-0 ocus:outline-none block text-teal-500 hover:text-slate-600 w-6 h-6">
                            <EmojiIcon />
                        </button> */}
                    </label>
                </div>
                <IconButton disabled={!isOtherUserConnected} icon={PaperPlaneIcon} onClick={sendMessage} title="Paper Plane" />
                <button className="rounded-md py-2 px-4 border-2 border-teal-500 text-teal-500 hover:bg-teal-500 hover:text-teal-100 focus:outline-none" onClick={skipButtonHandler}>{skipButtonText}</button>
                <button 
                    type="button" 
                    className={`relative ml-2 inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 dark:bg-gray-800 focus:ring-offset-2 focus:ring-gray-500 bg-gray-200 ${autoContinue ? 'bg-teal-500 dark:bg-teal-500' : ''}`}
                    onClick={() => setAutoContinue(!autoContinue)}
                >
                    <span className={`pointer-events-none relative inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200 ${autoContinue ? 'translate-x-5' : ''}`}>
                    </span>
                </button>
            </div>
        </div>
    );
}

export default ChatFooter;