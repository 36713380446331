import React, { useEffect, useState, useRef } from "react";
import { ChatMessage } from "../components/chat";
import ChatFooter from "../components/ChatFooter";

const ChatPage = ({ socket }) => {
    const [messages, setMessages] = useState([]);
    const [isTyping, setIsTyping] = useState(false);
    const messagesContainerRef = useRef();

    const clearMessages = () => {
        setMessages([]);
    }

    useEffect(() => {
        // Listen for incoming messages
        socket.on("new_message", (newMessage) => {
            setMessages((prevMessages) => [...prevMessages, newMessage]);
            setIsTyping(false);
            scrollToBottom();
        });

        socket.on('typing', ({ userId, isTyping }) => {
            if (userId !== socket.id) {
                setIsTyping(isTyping);
            }
        });

        // Clean up listeners when the component unmounts
        return () => {
            socket.off("new_message");
            socket.off('typing');
        };
    }, [socket]);

    const scrollToBottom = () => {
        if (messagesContainerRef.current) {
            // Use setTimeout to ensure the DOM has updated before scrolling
            setTimeout(() => {
                messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;
            }, 0);
        }
    };

    const messageComponents = messages.map((message, index) => (
        <ChatMessage
            key={index}
            data={{
                name: message.userId === socket.id ? 'Me' : 'Stranger',
                message: message.message,
                time: message.timestamp,
            }}
            isCurrentUser={message.userId === socket.id}
        />
    ));

    return (
        <div className="bg-zinc-200 dark:bg-zinc-900 max-h-screen h-full pt-32 transition-colors flex flex-col justify-center items-center">
            <div className="max-w-[700px] w-full h-full p-4 scroll-smooth overflow-auto transition-transform duration-1000 ease-in-out" ref={messagesContainerRef}>
                {messageComponents}
                {isTyping ? <ChatMessage data={{ name: "Stranger" }} isCurrentUser={false} /> : null}
            </div>
            <ChatFooter socket={socket} clearMessages={clearMessages} />
        </div>
    );
};

export default ChatPage;
