import React from "react";

const Layout = ({ children, className }) => {
  return (
    <div className={`bg-zinc-100 dark:bg-zinc-900 min-h-screen w-full ${className || ''}`}>
      <div className="relative mx-auto max-w-5xl lg:px-8">
        {children}
      </div>
    </div>
  );
};

export const PageLayout = ({ children, className }) => {
  return (
    <div className={`pt-24 px-4 sm:px-6 lg:px-0 ${className || ''}`}>
      {children}
    </div>
  );
}

export default Layout;
