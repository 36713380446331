import React from "react";
import rules from '../assets/data/rules.json'
import Footer from "../components/Footer";

const svgs = [
    {
        color: "text-blue-100",
        textColor: "text-blue-600",
        path: "M63.6911 28.8569C68.0911 48.8121 74.6037 61.2674 53.2349 65.9792C31.8661 70.6909 11.6224 61.2632 7.22232 41.308C2.82229 21.3528 3.6607 12.3967 25.0295 7.68503C46.3982 2.97331 59.2911 8.90171 63.6911 28.8569Z",
        width: "72",
        height: "75"
    },
    {
        color: "text-orange-100",
        textColor: "text-orange-600",
        path: "M62 13.001C62 33.4355 53.9345 64.001 33.5 64.001C13.0655 64.001 0 50.435 0 30.0005C0 9.56596 2.56546 4.00021 23 4.00021C43.4345 4.00021 62 -7.43358 62 13.001Z",
        width: "62",
        height: "64"
    },
    {
        color: "text-green-100",
        textColor: "text-green-600",
        path: "M65.5 30C65.5 50.4345 46.4345 68 26 68C5.56546 68 0 50.4345 0 30C0 9.56546 12.5655 0 33 0C53.4345 0 65.5 9.56546 65.5 30Z",
        width: "66",
        height: "68"
    },
    {
        color: "text-purple-100",
        textColor: "text-purple-600",
        path: "M65.5 30C65.5 50.4345 46.4345 68 26 68C5.56546 68 0 50.4345 0 30C0 9.56546 12.5655 0 33 0C53.4345 0 65.5 9.56546 65.5 30Z",
        width: "66",
        height: "68"
    },
    {
        color: "text-gray-100",
        textColor: "text-gray-600",
        path: "M64.5 26C64.5 46.4345 56.4345 70 36 70C15.5655 70 0 53.9345 0 33.5C0 13.0655 13.0655 0 33.5 0C53.9345 0 64.5 5.56546 64.5 26Z",
        width: "65",
        height: "70"
    },
    {
        color: "text-yellow-100",
        textColor: "text-yellow-500",
        path: "M8.49996 28.0002C4.09993 47.9554 14.1313 66.7885 35.5 71.5002C56.8688 76.2119 68.0999 58.4553 72.5 38.5001C76.9 18.5449 68.3688 12.711 47 7.99931C25.6312 3.28759 12.9 8.04499 8.49996 28.0002Z",
        width: "78",
        height: "78"
    },
    {
        color: "text-gray-100",
        textColor: "text-gray-600",
        path: "M62 13.001C62 33.4355 53.9345 64.001 33.5 64.001C13.0655 64.001 0 50.435 0 30.0005C0 9.56596 2.56546 4.00021 23 4.00021C43.4345 4.00021 62 -7.43358 62 13.001Z",
        width: "62",
        height: "64"
    },
    {
        color: "text-rose-100",
        textColor: "text-rose-600",
        path: "M63.6911 28.8569C68.0911 48.8121 74.6037 61.2674 53.2349 65.9792C31.8661 70.6909 11.6224 61.2632 7.22232 41.308C2.82229 21.3528 3.6607 12.3967 25.0295 7.68503C46.3982 2.97331 59.2911 8.90171 63.6911 28.8569Z",
        width: "72",
        height: "75"
    },
    {
        color: "text-lime-100",
        textColor: "text-lime-600",
        path: "M0 13.0264C0 33.4609 8.06546 64.0264 28.5 64.0264C48.9345 64.0264 62 50.4604 62 30.0259C62 9.59135 59.4345 4.0256 39 4.0256C18.5655 4.0256 0 -7.40819 0 13.0264Z",
        width: "62",
        height: "65"
    },
    // Add other SVG objects as needed
];

const FeatureCards = () => {
    return (
        <div className="grid grid-cols-1 gap-12 text-center sm:grid-cols-2 md:grid-cols-3 lg:gap-y-16">
            {rules.map((rule, index) => (
                <div key={index}>
                    <div className={`relative flex items-center justify-center mx-auto`}>
                        <svg
                            className={svgs[index % svgs.length].color}
                            width={svgs[index % svgs.length].width}
                            height={svgs[index % svgs.length].height}
                            viewBox={`0 0 ${svgs[index % svgs.length].width} ${svgs[index % svgs.length].height}`}
                            fill="currentColor"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path d={svgs[index % svgs.length].path} />
                        </svg>
                        <h2 className={`absolute ${svgs[index % svgs.length].textColor} text-3xl font-bold w-9 h-9`}>
                            {index + 1 < 10 ? '0' + (index + 1) : index + 1}
                        </h2>
                    </div>
                    <h3 className="mt-8 text-lg font-semibold text-gray-900 dark:text-gray-100">{rule.title}</h3>
                    <p className="mt-4 text-base text-gray-600 dark:text-gray-400">{rule.description}</p>
                </div>
            ))}
        </div>
    );
};

const RulesPage = () => {
    return (
        <div className="pt-24">
            <section className="py-10 sm:py-16 lg:py-24">
                <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
                    <FeatureCards />
                </div>
            </section>
            <Footer />
        </div>

    );
};

export default RulesPage;
