import React, { useState } from 'react';

const faqData = [
    {
        question: 'Is this omegle, but named differantly?',
        answer: 'Yes and no, omegle has been shut down forever, while Ættirtalk will be improved to prevent both bots and illegal stuff.'
    },
    {
        question: 'What is Ættirtalk?',
        answer: 'Ættirtalk (Aettirtalk, pronounced as "aat - ir - talk") is simply just like omegle, but in a modern and updated look. While using this chatapp you agree with the terms and policies and the rules established like above.'
    },
    {
        question: 'Will Ættirtalk stay up-to-date?',
        answer: 'Ættirtalk will be updated now and then, every bug, every rule-breaker will be traced, studied and repared to be able to stay online. When a huge update will be installed, it will be announced at least 2 days before the update.'
    },
    {
        question: 'Is there a social media account of Ættirtalk?',
        answer: 'Yes! You can find Ættirtalk easily on X (Twitter) and Instagram under @aettirtalk.'
    },
    {
        question: 'Where can I report an issue?',
        answer: 'Issues can be reported in the contact form down below. We will not allow spam or unneccessary emails, which results into a ban!'
    }
];

const FaqItem = ({ faq }) => {
    const [showAnswer, setShowAnswer] = useState(false);

    const handleToggleAnswer = () => {
        setShowAnswer(!showAnswer);
    };

    return (
        <div className="w-full border-b-2 border-slate-700 dark:border-slate-200 pb-4">
            <div className="flex-col justify-start items-center flex">
                <div className="self-stretch justify-between w-full items-start gap-6 inline-flex">
                    <div className="grow shrink basis-0 flex-col justify-start items-start gap-2 inline-flex">
                        <div className="text-gray-700 dark:text-zinc-100 text-lg font-medium">{faq.question}</div>
                        <div className={`text-gray-500 dark:text-gray-400 text-base font-normal transition-opacity duration-300 ease-in-out ${showAnswer ? 'opacity-100 h-full' : 'opacity-0 h-0'}`}>
                            {faq.answer}
                        </div>
                    </div>
                    <button className="ml-4" onClick={handleToggleAnswer}>
                        <ShowMoreButton showAnswer={showAnswer} />
                    </button>
                </div>
            </div>
        </div>
    );
};

const ShowMoreButton = ({ showAnswer }) => {
    return (
        <div className="relative w-[24px] h-[24px] p-1 border-[#7ABDFB] rounded-full border-2 group">
            <div className={`absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-3 h-[2px] bg-[#7ABDFB] group-hover:rotate-180 transition-transform duration-300 ease-in-out ${showAnswer ? 'rotate-180 group-hover:rotate-45' : ''}`} />
            <div className={`absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[2px] h-3 bg-[#7ABDFB] group-hover:rotate-90 transition-transform duration-300 ease-in-out ${showAnswer ? 'rotate-90 group-hover:rotate-45' : ''}`} />
        </div>
    );
};

const FaqSection = () => {
    return (
        <div className="py-24 flex-col items-center gap-16 flex mx-auto md:w-2/3 lg:w-2/3 xl:w-2/3">
            <div className="px-8 flex-col justify-start items-start gap-8 flex">
                <div className="self-stretch flex-col justify-start items-center gap-12 flex">
                    <div className="flex-col justify-start items-center gap-5 flex">
                        <div className="self-stretch text-center text-gray-700 dark:text-zinc-100 text-4xl font-semibold leading-[44px]">Frequently asked questions</div>
                        <div className="self-stretch text-center text-gray-500 dark:text-gray-400 text-xl font-normal leading-[30px]">Everything you need to know about Ættirtalk.</div>
                    </div>
                </div>
            </div>
            <div className="w-full md:w-2/3 lg:w-2/3 xl:w-2/3">
                <div className="px-8 flex-col justify-start items-center gap-16 flex">
                    {faqData.map((faq, index) => (
                        <FaqItem key={index} faq={faq} />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default FaqSection;
