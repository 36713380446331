import React from "react";
import { ReactComponent as FullLogoText } from '../assets/images/aettirtalk.svg';
import { ReactComponent as DownIcon } from '../assets/icons/chev-down.svg';

const Button = ({ children, to }) => {
    return (
        <a href={to} className="relative inline-flex items-center justify-start px-6 py-3 overflow-hidden font-medium transition-all shadow bg-white dark:bg-gray-800 rounded hover:bg-white group">
            <span className="w-48 h-48 rounded rotate-[-40deg] bg-purple-600 absolute bottom-0 left-0 -translate-x-full ease-out duration-500 transition-all translate-y-full mb-9 ml-9 group-hover:ml-0 group-hover:mb-32 group-hover:translate-x-0"></span>
            <span className="relative w-full text-left text-black dark:text-white transition-colors duration-300 ease-in-out group-hover:text-white">{children}</span>
        </a>
    );
}

const HeroSection = () => {
    return (
        <div className="flex flex-col items-center justify-evenly pt-28 gap-28">
            <div className="w-full flex justify-center">
                <FullLogoText className="logoText dark:lightText max-w-full" />
            </div>
            <div>
                <div className="text-center text-zinc-700 dark:text-zinc-100 text-[40px] font-bold">
                    Discover your friends!
                </div>
                <p className="max-w-[750px] text-center text-gray-500 dark:text-gray-400 text-xl font-normal font-['DM Sans'] leading-loose">
                    Welcome to Ættirtalk! Discover friends worldwide through anonymous chats. Uncover diverse perspectives and forge unexpected friendships – your next great connection is just a chat away.
                </p>
            </div>
            <div className="w-full flex justify-center gap-10">
                <Button to="/rules" >Rules</Button>
                <Button to="/chat" >Start Chatting</Button>
            </div>
            <DownIcon className="animate-up-and-down" />
        </div>
    );
}

export default HeroSection;