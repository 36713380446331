import React from "react";
import { ReactComponent as FullLogoText } from '../assets/images/aettirtalk.svg';

const AboutSection = () => {
    return (
        <section className="flex flex-col items-center justify-evenly py-48 gap-28">
            <div>
                <div className="text-center md:text-left text-zinc-700 dark:text-zinc-100 text-[40px] font-bold">
                    Discover your friends!
                </div>
                <p className="max-w-[750px] text-center md:text-left text-gray-500 dark:text-gray-400 text-xl font-normal font-['DM Sans'] leading-loose">
                    Welcome to Ættirtalk! Discover friends worldwide through anonymous chats. Uncover diverse perspectives and forge unexpected friendships – your next great connection is just a chat away.
                </p>
            </div>
        </section>
    );
}

export default AboutSection;