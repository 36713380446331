import React, { useEffect, useState, useRef } from 'react';
import { Route, createRoutesFromElements, createBrowserRouter, RouterProvider } from 'react-router-dom';
import { socket, REQ_URL } from '../socket';
import ChatPage from "../pages/ChatPage";
import LandingPage from "../pages/LandingPage";
import RulesPage from '../pages/RulesPage';
import ComingSoon from '../pages/ComingSoon';
import AboutPage from '../pages/AboutPage';
import Navbar from '../components/Navbar';
import { useNotification } from '../features/Notifications';
import axios from 'axios';
import ErrorPage from '../pages/ErrorPage';
import TermsAndPrivacyPage from '../pages/TermsPage';
import { useData } from '../components/Language';


const routes = createRoutesFromElements(
    <Route path="/" >
        <Route index element={<LandingPage socket={socket} />} />
        <Route path="about" element={<AboutPage />} />
        <Route path="terms" element={<TermsAndPrivacyPage type="terms" />} />
        <Route path="privacy" element={<TermsAndPrivacyPage type="privacy" />} />
        <Route path="chat" element={<ChatPage socket={socket} />} />
        <Route path="rules" element={<RulesPage socket={socket} />} />
        <Route path="coming-soon/:timestamp" element={<ComingSoon />} />
        <Route path="*" element={<ErrorPage code={404} />} />
    </Route>
);

const ComingSoonRoute = ({ timestamp }) => {
    const routes = createRoutesFromElements(
        <Route path="/">
            <Route index element={<ComingSoon timestamp={timestamp} />} />
            <Route path="about" element={<AboutPage />} />
            <Route path="terms" element={<TermsAndPrivacyPage type="terms" />} />
            <Route path="privacy" element={<TermsAndPrivacyPage type="privacy" />} />
            <Route path="*" element={<ErrorPage code={404} />} />
        </Route>
    );

    const provider = createBrowserRouter(routes);

    return (
        <RouterProvider router={provider} />
    );
}

const provider = createBrowserRouter(routes);

const ÆttirRouter = ({ setLoader }) => {
    const [timestamp, setTimestamp] = useState(null);
    const [isTimestampLoaded, setIsTimestampLoaded] = useState(false);
    const socketRef = useRef(socket);
    const { setError } = useNotification();
    const { getData } = useData();

    useEffect(() => {
        // Fetch the timestamp from the server at aettirtalk.com:5000
        axios.get(`${REQ_URL}/getTimestamp`)
            .then(response => {
                setTimestamp(response.data.timestamp);
            })
            .catch(error => {
                setError(getData('errors.server_connection.title'), getData('errors.server_connection.message'));
            })
            .finally(() => setIsTimestampLoaded(true));

        // Ensure the socket is connected
        if (!socketRef.current.connected) {
            socketRef.current.connect();
        }

        // Attach event listeners
        const handleConnect = () => {
            console.log('Socket connected');
        };

        const handleError = (error) => {
            console.error('Socket connection error:', error);
        };

        // Add event listeners
        socketRef.current.on('connect', handleConnect);
        socketRef.current.on('connect_error', handleError);

        // Cleanup event listeners and disconnect on component unmount
        const currentSocket = socketRef.current;
        return () => {
            currentSocket.off('connect', handleConnect);
            currentSocket.off('connect_error', handleError);
            currentSocket.disconnect();
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>

            <Navbar />
            {
                isTimestampLoaded ? new Date(timestamp) > new Date()
                    ? <ComingSoonRoute timestamp={timestamp} />
                    : <RouterProvider router={provider} /> : <ComingSoonRoute timestamp={timestamp} />
            }
            {/* <RouterProvider router={provider} /> */}

        </>
    );
};

export default ÆttirRouter;