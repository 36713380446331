import React, { createContext, useContext, useEffect, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faX } from '@fortawesome/free-solid-svg-icons';

const NotificationContext = createContext();

const Notification = ({ isError, isOpen, onClose, title, message }) => {
    const [showModal, setShowModal] = useState(false);
    const [animationStyle, setAnimationStyle] = useState('translate-y-[-300px]');

    const currentTime = new Date();
    const formattedTime = currentTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });

    useEffect(() => {
        let openTimeout, closeTimeout;
        if (isOpen) {
            setShowModal(true);
            openTimeout = setTimeout(() => {
                setAnimationStyle('translate-y-0');
            }, 300);
        } else {
            setAnimationStyle('translate-y-[-300px]');
            closeTimeout = setTimeout(() => {
                setShowModal(false);
            }, 300);
        }

        return () => {
            clearTimeout(openTimeout);
            clearTimeout(closeTimeout);
        };
    }, [isOpen]);

    return (
        <>
            {(showModal) && (
                <div className="fixed inset-0 z-50 overflow-auto flex items-center justify-center">
                    <div className="absolute inset-0 bg-zinc-900/75 dark:bg-zinc-800/75 backdrop-blur duration-300 transition">
                        <div className={`max-w-[500px] w-fit h-fit  ${isError ? 'bg-red-50' : 'bg-green-50'} absolute right-1/2 translate-x-1/2 rounded-md overflow-hidden p-5 my-2.5 gr flex shadow shadow-transparent top-4 duration-300 transition ${animationStyle}`}>
                            <div className='hidden sm:flex items-center'>
                                <div className={`h-12 flex justify-center items-center rounded-full text-white p-3.5 aspect-square ${isError ? 'bg-red-500' : 'bg-green-500'}`}>
                                    {isError
                                        ? <FontAwesomeIcon icon={faX} />
                                        : <FontAwesomeIcon icon={faCheck} />
                                    }
                                </div>
                            </div>
                            <div className="h-fit min-w-[200px] p-[10px]">
                                <h4 className={`block font-bold text-sm	 ${isError ? 'text-red-500' : 'text-green-500'}`}>{title}</h4>
                                <p className="text-zinc-500 text-xs">{message} - <span className="text-zinc-800 font-bold">{formattedTime}</span></p>
                            </div>
                            <button
                                onClick={onClose}
                                className="text-zinc-950 hover:text-gray-500 w-fit h-min transition duration-300"
                            >
                                <FontAwesomeIcon icon={faX} size='sm' />
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

const NotificationProvider = ({ children }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [modalTitle, setModalTitle] = useState('');
    const [isModalError, setIsModalError] = useState(false);

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const setSuccess = (successTitle, successMessage) => {
        setIsModalOpen(true);
        setModalMessage(successMessage);
        setModalTitle(successTitle);
        setIsModalError(false);
    };

    const setError = (errorTitle, errorMessage) => {
        setIsModalOpen(true);
        setModalMessage(errorMessage);
        setModalTitle(errorTitle);
        setIsModalError(true);
    };

    return (
        <NotificationContext.Provider value={{ setSuccess, setError }}>
            {children}
            <Notification isError={isModalError} isOpen={isModalOpen} onClose={closeModal} message={modalMessage} title={modalTitle} />
        </NotificationContext.Provider>
    );
};

export const useNotification = () => {
    return useContext(NotificationContext);
};

export default NotificationProvider;
