// analytics.js

import ReactGA from 'react-ga';

export const initGA = () => {
    ReactGA.initialize('G-E4ZX0GW8PB'); // Replace with your actual tracking code
};

export const logPageView = () => {
    ReactGA.set({ page: window.location.pathname });
    ReactGA.pageview(window.location.pathname);
};
