import React, { useState, useEffect } from "react";

const PageLoader = ({ className }) => {
    return (
        <div className={`absolute w-full h-screen bg-zinc-100 dark:bg-zinc-900 z-50 transition-opacity duration-500 ${className}`}>
            <div className="spinner relative top-[35%] w-20 h-20 my-0 mx-auto bg-white rounded-full"></div>
            <div className="text-center text-gray-500 top-[40%] relative uppercase tracking-[.3em] font-bold leading-normal">ÆttirTalk is loading...</div>
        </div>
    );
};


const Loader = ({ children, isLoading }) => {
    const [showLoader, setShowLoader] = useState(isLoading);

    useEffect(() => {
        if (!isLoading) {
            const fadeTimeout = setTimeout(() => {
                setShowLoader(false);
            }, 500);
            return () => clearTimeout(fadeTimeout);
        } else {
            setShowLoader(true);
        }
    }, [isLoading]);

    return (
        <>
            {(showLoader) && ( <PageLoader className={`${isLoading ? '' : 'opacity-0'}`}/> )}
            {children}
        </>
    );
};

export default Loader;
