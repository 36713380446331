import React, { useState } from "react";
import axios from 'axios';
import { useData } from "./Language";
import { useNotification } from "../features/Notifications";
import { DefaultButton } from "./Buttons";
import { REQ_URL } from "../socket";

export const NotifyMeForm = () => {
    const [email, setEmail] = useState('');
    const { setSuccess, setError } = useNotification();
    const { getData } = useData();

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        const email = event.target.email.value;
        const isEmailValid = validateEmail(email);

        if (!isEmailValid) {
            setError(getData('errors.signup_failure_by_invalid_email.title'), getData('errors.signup_failure_by_invalid_email.message'));
            return;
        }

        try {
            await axios.post(`${REQ_URL}/storeEmail`, { email });
            setSuccess(getData('successes.signup_success.title'), getData('successes.signup_success.message', {EMAIL: email}));
            setEmail('');
        } catch (error) {
            console.error('Error:', error);
            setError(getData('errors.signup_failure_by_server.title'), getData('errors.signup_failure_by_server.message'));
        }
    };

    return (
        <form className="sm:mx-auto sm:max-w-xl lg:mx-0" onSubmit={handleSubmit}>
            <div className="sm:flex">
                <div className="min-w-0 flex-1">
                    <label htmlFor="email" className="sr-only">Email address</label>
                    <input id="email" type="email" onChange={(e) => setEmail(e.target.value)} placeholder={getData('forms.email')} className="block w-full rounded-md border-0 bg-gray-200 px-4 py-3 text-base text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-gray-400 dark:text-gray-100 dark:bg-gray-800" value={email} autoComplete="off" />
                </div>
                <div className="mt-3 sm:mt-0 sm:ml-3">
                    <DefaultButton type="submit">
                        {getData('forms.join_notifylist')}
                    </DefaultButton>
                </div>
            </div>
        </form>
    );
}