import { ReactComponent as AfghanistanFlag } from './af.svg';
import { ReactComponent as AlbaniaFlag } from './al.svg';
import { ReactComponent as AlgeriaFlag } from './ad.svg';
import { ReactComponent as ArmeniaFlag } from './am.svg';
import { ReactComponent as AzerbaijanFlag } from './az.svg';
import { ReactComponent as BangladeshFlag } from './bd.svg';
import { ReactComponent as BelarusFlag } from './by.svg';
import { ReactComponent as BosniaFlag } from './ba.svg';
import { ReactComponent as BulgariaFlag } from './bg.svg';
import { ReactComponent as BurundiFlag } from './bi.svg';
import { ReactComponent as CambodiaFlag } from './kh.svg';
import { ReactComponent as ChinaFlag } from './cn.svg';
import { ReactComponent as CroatiaFlag } from './hr.svg';
import { ReactComponent as CzechRepublicFlag } from './cz.svg';
import { ReactComponent as DenmarkFlag } from './dk.svg';
import { ReactComponent as EritreaFlag } from './er.svg';
import { ReactComponent as EstoniaFlag } from './ee.svg';
import { ReactComponent as SwazilandFlag } from './sz.svg';
import { ReactComponent as EthiopiaFlag } from './et.svg';
import { ReactComponent as FaroeIslandsFlag } from './fo.svg';
import { ReactComponent as FinlandFlag } from './fi.svg';
import { ReactComponent as FranceFlag } from './fr.svg';
import { ReactComponent as GeorgiaFlag } from './ge.svg';
import { ReactComponent as GermanyFlag } from './de.svg';
import { ReactComponent as GreeceFlag } from './gr.svg';
import { ReactComponent as GreenlandFlag } from './gl.svg';
import { ReactComponent as HungaryFlag } from './hu.svg';
import { ReactComponent as IcelandFlag } from './is.svg';
import { ReactComponent as IndiaFlag } from './in.svg';
import { ReactComponent as IndonesiaFlag } from './id.svg';
import { ReactComponent as IranFlag } from './ir.svg';
import { ReactComponent as IrelandFlag } from './ie.svg';
import { ReactComponent as IsraelFlag } from './il.svg';
import { ReactComponent as ItalyFlag } from './it.svg';
import { ReactComponent as JapanFlag } from './jp.svg';
import { ReactComponent as KazakhstanFlag } from './kz.svg';
import { ReactComponent as KenyaFlag } from './ke.svg';
import { ReactComponent as KyrgyzstanFlag } from './kg.svg';
import { ReactComponent as LaosFlag } from './la.svg';
import { ReactComponent as LatviaFlag } from './lv.svg';
import { ReactComponent as LesothoFlag } from './ls.svg';
import { ReactComponent as LithuaniaFlag } from './lt.svg';
import { ReactComponent as LuxembourgFlag } from './lu.svg';
import { ReactComponent as MadagascarFlag } from './mg.svg';
import { ReactComponent as MalaysiaFlag } from './my.svg';
import { ReactComponent as MaldivesFlag } from './mv.svg';
import { ReactComponent as MaltaFlag } from './mt.svg';
import { ReactComponent as MongoliaFlag } from './mn.svg';
import { ReactComponent as MontenegroFlag } from './me.svg';
import { ReactComponent as MyanmarFlag } from './mm.svg';
import { ReactComponent as NepalFlag } from './np.svg';
import { ReactComponent as NetherlandsFlag } from './nl.svg';
import { ReactComponent as NorthMacedoniaFlag } from './mk.svg';
import { ReactComponent as NorwayFlag } from './no.svg';
import { ReactComponent as PakistanFlag } from './pk.svg';
import { ReactComponent as PhilippinesFlag } from './ph.svg';
import { ReactComponent as PolandFlag } from './pl.svg';
import { ReactComponent as PortugalFlag } from './pt.svg';
import { ReactComponent as RomaniaFlag } from './ro.svg';
import { ReactComponent as RussiaFlag } from './ru.svg';
import { ReactComponent as RwandaFlag } from './rw.svg';
import { ReactComponent as SamoaFlag } from './ws.svg';
import { ReactComponent as SaudiArabiaFlag } from './sa.svg';
import { ReactComponent as SerbiaFlag } from './rs.svg';
import { ReactComponent as SlovakiaFlag } from './sk.svg';
import { ReactComponent as SloveniaFlag } from './si.svg';
import { ReactComponent as SomaliaFlag } from './so.svg';
import { ReactComponent as SouthAfricaFlag } from './za.svg';
import { ReactComponent as SouthKoreaFlag } from './kr.svg';
import { ReactComponent as SpainFlag } from './es.svg';
import { ReactComponent as SriLankaFlag } from './lk.svg';
import { ReactComponent as SwedenFlag } from './se.svg';
import { ReactComponent as TajikistanFlag } from './tj.svg';
import { ReactComponent as ThailandFlag } from './th.svg';
import { ReactComponent as TurkmenistanFlag } from './tm.svg';
import { ReactComponent as TurkeyFlag } from './tr.svg';
import { ReactComponent as UkraineFlag } from './ua.svg';
import { ReactComponent as UnitedKingdomFlag } from './gb.svg';
import { ReactComponent as UzbekistanFlag } from './uz.svg';
import { ReactComponent as VanuatuFlag } from './vu.svg';
import { ReactComponent as VietnamFlag } from './vn.svg';

export const languages = [
    { code: 'af', name: 'Afrikaans', flag: SouthAfricaFlag },
    { code: 'am', name: 'አማርኛ', flag: EthiopiaFlag },
    { code: 'ar', name: 'العربية', flag: SaudiArabiaFlag },
    { code: 'az', name: 'Azərbaycanca', flag: AzerbaijanFlag },
    { code: 'be', name: 'Беларуская', flag: BelarusFlag },
    { code: 'bg', name: 'Български', flag: BulgariaFlag },
    { code: 'bi', name: 'Bislama', flag: VanuatuFlag },
    { code: 'bn', name: 'বাংলা', flag: BangladeshFlag },
    { code: 'bs', name: 'Bosanski', flag: BosniaFlag },
    { code: 'ca', name: 'Català', flag: AlgeriaFlag },
    { code: 'cnr', name: 'Crnogorski', flag: MontenegroFlag },
    { code: 'cs', name: 'Čeština', flag: CzechRepublicFlag },
    { code: 'da', name: 'Dansk', flag: DenmarkFlag },
    { code: 'de', name: 'Deutsch', flag: GermanyFlag },
    { code: 'dv', name: 'ދިވެހިބަސް', flag: MaldivesFlag },
    { code: 'el', name: 'Ελληνικά', flag: GreeceFlag },
    { code: 'en', name: 'English', flag: UnitedKingdomFlag },
    { code: 'es', name: 'Español', flag: SpainFlag },
    { code: 'et', name: 'Eesti', flag: EstoniaFlag },
    { code: 'fa', name: 'فارسی', flag: IranFlag },
    { code: 'fi', name: 'Suomi', flag: FinlandFlag },
    { code: 'fil', name: 'Filipino', flag: PhilippinesFlag },
    { code: 'fo', name: 'Føroyskt', flag: FaroeIslandsFlag },
    { code: 'fr', name: 'Français', flag: FranceFlag },
    { code: 'ga', name: 'Gaeilge', flag: IrelandFlag },
    { code: 'he', name: 'עברית', flag: IsraelFlag },
    { code: 'hi', name: 'हिन्दी', flag: IndiaFlag },
    { code: 'hr', name: 'Hrvatski', flag: CroatiaFlag },
    { code: 'hu', name: 'Magyar', flag: HungaryFlag },
    { code: 'hy', name: 'Հայերեն', flag: ArmeniaFlag },
    { code: 'id', name: 'Bahasa Indonesia', flag: IndonesiaFlag },
    { code: 'is', name: 'Íslenska', flag: IcelandFlag },
    { code: 'it', name: 'Italiano', flag: ItalyFlag },
    { code: 'ja', name: '日本語', flag: JapanFlag },
    { code: 'ka', name: 'ქართული', flag: GeorgiaFlag },
    { code: 'kk', name: 'Қазақша', flag: KazakhstanFlag },
    { code: 'kl', name: 'Kalaallisut', flag: GreenlandFlag },
    { code: 'km', name: 'ខ្មែរ', flag: CambodiaFlag },
    { code: 'ko', name: '한국어', flag: SouthKoreaFlag },
    { code: 'ky', name: 'Кыргызча', flag: KyrgyzstanFlag },
    { code: 'lb', name: 'Lëtzebuergesch', flag: LuxembourgFlag },
    { code: 'lo', name: 'ລາວ', flag: LaosFlag },
    { code: 'lt', name: 'Lietuvių', flag: LithuaniaFlag },
    { code: 'lv', name: 'Latviešu', flag: LatviaFlag },
    { code: 'mg', name: 'Malagasy', flag: MadagascarFlag },
    { code: 'mk', name: 'Македонски', flag: NorthMacedoniaFlag },
    { code: 'mn', name: 'Монгол', flag: MongoliaFlag },
    { code: 'ms', name: 'Bahasa Melayu', flag: MalaysiaFlag },
    { code: 'mt', name: 'Malti', flag: MaltaFlag },
    { code: 'my', name: 'ဗမာစကာ', flag: MyanmarFlag },
    { code: 'ne', name: 'नेपाली', flag: NepalFlag },
    { code: 'nl', name: 'Nederlands', flag: NetherlandsFlag },
    { code: 'no', name: 'Norsk', flag: NorwayFlag },
    { code: 'pl', name: 'Polski', flag: PolandFlag },
    { code: 'ps', name: 'پښتو', flag: AfghanistanFlag },
    { code: 'pt', name: 'Português', flag: PortugalFlag },
    { code: 'rn', name: 'Kirundi', flag: BurundiFlag },
    { code: 'ro', name: 'Română', flag: RomaniaFlag },
    { code: 'ru', name: 'Русский', flag: RussiaFlag },
    { code: 'rw', name: 'Ikinyarwanda', flag: RwandaFlag },
    { code: 'si', name: 'සිංහල', flag: SriLankaFlag },
    { code: 'sk', name: 'Slovenčina', flag: SlovakiaFlag },
    { code: 'sl', name: 'Slovenščina', flag: SloveniaFlag },
    { code: 'sm', name: 'Samoan', flag: SamoaFlag },
    { code: 'so', name: 'Soomaali', flag: SomaliaFlag },
    { code: 'sq', name: 'Shqip', flag: AlbaniaFlag },
    { code: 'sr', name: 'Српски', flag: SerbiaFlag },
    { code: 'ss', name: 'SiSwati', flag: SwazilandFlag },
    { code: 'st', name: 'Sesotho', flag: LesothoFlag },
    { code: 'sv', name: 'Svenska', flag: SwedenFlag },
    { code: 'sw', name: 'Kiswahili', flag: KenyaFlag },
    { code: 'tg', name: 'Тоҷикӣ', flag: TajikistanFlag },
    { code: 'th', name: 'ไทย', flag: ThailandFlag },
    { code: 'ti', name: 'ትግርኛ', flag: EritreaFlag },
    { code: 'tk', name: 'Türkmen', flag: TurkmenistanFlag },
    { code: 'tr', name: 'Türkçe', flag: TurkeyFlag },
    { code: 'uk', name: 'Українська', flag: UkraineFlag },
    { code: 'ur', name: 'اردو', flag: PakistanFlag },
    { code: 'uz', name: 'O‘zbekcha', flag: UzbekistanFlag },
    { code: 'vi', name: 'Tiếng Việt', flag: VietnamFlag },
    { code: 'zh', name: '中文', flag: ChinaFlag },
];