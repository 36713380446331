import { io } from 'socket.io-client';

const NODE_URL = process.env.NODE_ENV === 'production' ? '' : 'http://localhost:5000';
const REQ_URL = process.env.NODE_ENV === 'production' ? '/req/' : NODE_URL;

const socket = io(NODE_URL, {
    transports: ['websocket'],
});
  
export {
    socket,
    NODE_URL,
    REQ_URL
}