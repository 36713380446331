import React, { useState, useEffect } from "react";
import { ReactComponent as LogoIcon } from '../assets/images/logo.svg';
import { ReactComponent as HalfLogoText } from '../assets/images/aettir.svg';

import { ReactComponent as SettingsIcon } from '../assets/icons/settings.svg';
import { ReactComponent as ProfileIcon } from '../assets/icons/profile.svg';
import { ReactComponent as NotificationIcon } from '../assets/icons/notification.svg';
import { ReactComponent as LogoutIcon } from '../assets/icons/logout.svg';
import { socket } from '../socket';
import LightDarkSwitch from "./LightDarkSwitch";
import { LanguageSwitch } from '../components/Language';

const Settings = () => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className="relative group">
            <button className="relative z-20 flex items-center justify-center rounded-full w-[50px] h-[50px] transform transition-all bg-slate-700 ring-0 ring-gray-300 hover:ring-8 group-focus:ring-8 ring-opacity-30 duration-200 shadow-md" onClick={() => setIsOpen(!isOpen)}>
                <div className="flex flex-col justify-between w-fit h-fit transform transition-all duration-300 group-focus:-rotate-[45deg] origin-center">
                    {/* <div className="bg-white h-[2px] w-1/2 rounded transform transition-all duration-300 group-focus:-rotate-90 group-focus:h-[1px] origin-right delay-75 group-focus:-translate-y-[1px]"></div>
                    <div className="bg-white h-[1px] rounded"></div>
                    <div className="m-0 bg-white h-[2px] w-1/2 rounded self-end transform transition-all duration-300 group-focus:-rotate-90 group-focus:h-[1px] origin-left delay-75 group-focus:translate-y-[1px]"></div> */}
                    <SettingsIcon className="h-6 w-6 text-sky-900  dark:text-sky-100 group-hover:-rotate-[45deg] transform transition-all duration-300" />
                </div>
            </button>
            <div className={`absolute bg-white w-0 h-0 overflow-hidden rounded-md right-6 top-5 transition-all ${(isLoggedIn && isOpen) || isOpen ? 'h-[162px]' : 'h-[89px]'} ${isOpen ? 'w-40' : ''} delay-150 duration-300 shadow-xl`}>
                <ul className="divide-y py-2 text-left text-sm text-sky-700">
                    {isLoggedIn && (
                        <li className="flex px-4 py-2 hover:bg-gray-100">
                            <ProfileIcon className="h-5 w-5 text-sky-900 mr-2" />
                            Profile
                        </li>
                    )}
                    {/* <li className="flex px-4 py-2 hover:bg-gray-100">
                        <NotificationIcon className="h-5 w-5 text-sky-900 mr-2" />
                        Notification
                    </li> */}
                    <li className="flex px-4 py-2 hover:bg-gray-100">
                        <LightDarkSwitch />
                    </li>
                    <li className="flex px-4 py-2 hover:bg-gray-100">
                        <LanguageSwitch />
                    </li>
                    {isLoggedIn && (
                        <li className="flex px-4 py-2 hover:bg-gray-100">
                            <LogoutIcon className="h-5 w-5 text-sky-900 mr-2" />
                            Logout
                        </li>
                    )}
                </ul>
            </div>
        </div>
    );
}

const Navbar = () => {
    const [totalOnline, setTotalOnline] = useState(0);

    useEffect(() => {
        const handleOnlineUsers = (onlineUsers) => {
            setTotalOnline(onlineUsers);
        };

        socket.on("online_users", handleOnlineUsers);

        return () => {
            socket.off("online_users", handleOnlineUsers);
        };
    }, []);

    return (
        <div className="h-24 absolute left-0 top-6 z-40 w-full px-4 sm:px-8 lg:px-16">
            <div className="w-full border-[#33415580] dark:border-[#cbd5e180] border-2 backdrop-blur bg-[#cbd5e120] dark:bg-[#33415520] h-full flex items-center rounded-2xl">
                <div className="w-full flex h-[30px] px-5 gap-3 items-center justify-around">
                    <a href="./" className="flex gap-3 h-full items-center">
                        <LogoIcon className="max-h-full w-fit" />
                        <HalfLogoText className="hidden sm:block logoText dark:lightText max-h-full w-fit" />
                    </a>
                    {/* <p className="text-gray-900 dark:text-gray-100">Total online: {totalOnline}</p> */}
                    <div className="flex gap-3">
                        <LanguageSwitch />
                        <LightDarkSwitch />
                    </div>
                    
                    {/* <Settings /> */}
                    
                </div>
            </div>
        </div>
    );
}

export default Navbar;