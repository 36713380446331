import React, { useRef, useCallback, createContext, useContext, useEffect, useState } from "react";
import { languages } from "../assets/icons/flags";
import defaultLangData from "../assets/data/en.json";
import axios from 'axios';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { useNotification } from "../features/Notifications";
import { REQ_URL } from "../socket";


// Create a context for the language
const LangContext = createContext();

const FlagIcon = ({ Flag, altText }) => Flag && <Flag className="mr-2 w-5 h-5" alt={altText} />;

const LanguageOption = ({ language, onClick }) => (
    <button
        onClick={onClick}
        className="flex text-left w-full px-4 py-2 text-sm leading-5 text-gray-700 dark:text-gray-300 hover:bg-gray-200 dark:hover:bg-gray-800 hover:text-gray-900 dark:hover:text-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
        role="menuitem"
    >
        <FlagIcon Flag={language.flag} altText={`Flag of ${language.code}`} />
        <span className="hidden md:inline">{language.name}</span>
    </button>
);

export const useData = () => {
    const { languageData, defaultLangData } = useContext(LangContext);

    const getProperty = (obj, prop) => {
        const [current, ...rest] = prop.split('.');

        if (obj && obj.hasOwnProperty(current)) {
            if (rest.length === 0) {
                return obj[current];
            } else {
                return getProperty(obj[current], rest.join('.'));
            }
        }

        return undefined;
    };

    const replacePlaceholders = (template, data) => {
        for (let key in data) {
            if (data.hasOwnProperty(key)) {
                const placeholder = new RegExp(`{${key}}`, 'g');
                template = template.replace(placeholder, data[key]);
            }
        }
        return template;
    };

    const getData = (property, ...additionalData) => {
        const languageValue = getProperty(languageData, property);
        const defaultValue = getProperty(defaultLangData, property);

        const template = languageValue !== undefined ? languageValue : defaultValue;

        let processedTemplate = template;
        if (additionalData.length > 0) {
            processedTemplate = replacePlaceholders(template, additionalData[0]);
        }

        return processedTemplate;
    };

    return { getData };
};

// LangProvider component to wrap your entire application
export const LangProvider = ({ children, setIsLoading }) => {
    const [language, setLanguage] = useState('');
    const [languageData, setLanguageData] = useState(null);
    const { setError } = useNotification();

    const changeLanguage = (newLanguage) => {
        fetchLanguageData(newLanguage);
        localStorage.setItem('userLanguagePreference', newLanguage);
    };

    const fetchLanguageData = useCallback(async (code) => {
        setIsLoading(true);
        try {
            const response = await axios.get(`${REQ_URL}/lang/${code}`);
            const data = response.data;
            setLanguageData(data);
            setLanguage(code);
            document.documentElement.setAttribute('lang', code);
        } catch (error) {
            setLanguageData(defaultLangData);
            setLanguage("en");
            document.documentElement.setAttribute('lang', "en");
            setError("Language Retrievement Error", "Something is wrong with the server and we couldn't therefore retrieve the language data for you. Please try again later or use English as standard.");
        } finally {
            setIsLoading(false);
        }
    }, [setIsLoading, setError]);

    useEffect(() => {
        const storedLanguage = localStorage.getItem('userLanguagePreference');
        const userLanguage = storedLanguage || navigator.language.split('-')[0];

        // If userLanguage is not set in localStorage, set it to the default value
        if (!storedLanguage) {
            localStorage.setItem('userLanguagePreference', userLanguage);
        }

        fetchLanguageData(userLanguage);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); // Empty dependency array ensures the effect runs only once on mount

    // Provide the language state and changeLanguage function to the entire app
    return (
        <LangContext.Provider value={{ language, languageData, defaultLangData, changeLanguage }}>
            {children}
        </LangContext.Provider>
    );
};

export const LanguageSwitch = () => {
    const { language, languageData, defaultLangData, changeLanguage } = useContext(LangContext);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [languagesWithFlags, setLanguagesWithFlags] = useState(languages);
    const [selectedLanguage, setSelectedLanguage] = useState(null);
    const [filteredLanguage, setFilteredLanguage] = useState(languages);
    const [inputValue, setInputValue] = useState('');
    const inputRef = useRef(null);
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 640);

    const initializeFlags = useCallback(() => {
        const defaultLanguage = languages.find((lang) => lang.code === language);

        setLanguagesWithFlags(languages);
        setSelectedLanguage(defaultLanguage || languages[0]);
    }, [language]);

    useEffect(() => {
        initializeFlags();
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth < 640);
        };

        // Add event listener to update isSmallScreen on window resize
        window.addEventListener('resize', handleResize);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [initializeFlags]);

    const handleLanguageChange = (lang) => {
        changeLanguage(lang.code);
        handleDropdownToggle(false);
        setFilteredLanguage(filterLanguages(''));
        setInputValue('');
    };

    const handleKeyDown = (e) => {
        
        if (e.key === 'Enter') {
            e.preventDefault();
            if (filteredLanguage.length > 0) {
                handleLanguageChange(filteredLanguage[0]);
            }
        }
    };

    const handleDropdownToggle = (state) => {
        state && inputRef.current.focus();
        setIsDropdownOpen(state)
    }

    const handleInputChange = (e) => {
        handleDropdownToggle(true);
        setInputValue(e.target.value);

        e.target.value
            ? setFilteredLanguage(filterLanguages(e.target.value))
            : setFilteredLanguage(languages);
    }

    const filterLanguages = (input) => {
        const inputValueLower = input.toLowerCase().trim();

        // Use regex to match languages containing the input
        const regex = new RegExp(inputValueLower, 'i');
        return languages.filter(
            (language) => regex.test(language.name.toLowerCase())
        );
    };

    return (
        <div className="relative inline-block text-left">
            <div className="w-fit md:min-w-[144px]">
                <span className="rounded-md shadow-sm">
                    <button
                        className="inline-flex justify-between items-center w-full rounded-md border border-gray-300 dark:border-gray-800 p-2 bg-gray-100 dark:bg-gray-900 text-sm leading-5 font-medium focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-800 dark:text-gray-200 transition ease-in-out duration-150 min-w-full gap-3"
                        id="options-menu"
                        aria-haspopup="true"
                        aria-expanded={isDropdownOpen ? 'true' : 'false'}
                        onClick={() => handleDropdownToggle(!isDropdownOpen)}
                    >
                        <div className="flex items-center">
                            {languagesWithFlags.length > 0 && selectedLanguage && selectedLanguage.flag && (
                                <selectedLanguage.flag className="w-6 h-6" alt={`Flag of ${selectedLanguage.code}`} />
                            )}
                            <input
                                type="text"
                                ref={inputRef}
                                placeholder={selectedLanguage && selectedLanguage.name}
                                value={inputValue}
                                onChange={(e) => handleInputChange(e)}
                                onKeyDown={(e) => handleKeyDown(e)}
                                className="ml-2 bg-transparent py-2 text-sm leading-5 text-gray-700 placeholder:text-gray-700 dark:text-gray-300 dark:placeholder:text-gray-300 focus:outline-none focus:text-gray-900 max-w-0 md:max-w-[250px] text-ellipsis"
                                style={isSmallScreen ? null : { width: inputValue ? `${(inputValue.length + 1) * 8}px` : `${(selectedLanguage?.name.length + 1) * 8}px`, minWidth: `${(selectedLanguage?.name.length + 1) * 8}px` }}
                            />

                        </div>
                        <div className="flex justify-center items-center">
                            <FontAwesomeIcon icon={faChevronDown} className={`transition-transform duration-300  ${isDropdownOpen ? 'rotate-180' : ''}`} />
                        </div>
                    </button>
                </span>
            </div>
            <div className={`origin-top-right absolute left-0 mt-2 w-full rounded-md shadow-lg ${isDropdownOpen ? '' : 'hidden'} overflow-y-auto scrollbar-thin max-h-40`}>
                {isDropdownOpen && (
                    <div className="rounded-md bg-gray-100 dark:bg-gray-900 shadow-xs" >
                        <div
                            className="py-1"
                            role="menu"
                            aria-orientation="vertical"
                            aria-labelledby="options-menu"
                        >
                            {filteredLanguage.length > 0 ? filteredLanguage.map((language, i) => (
                                <LanguageOption
                                    key={i}
                                    language={language}
                                    onClick={() => handleLanguageChange(language)}
                                />
                            )) : (
                                <div
                                    className="flex text-left w-full px-4 py-2 text-sm leading-5 text-gray-700 dark:text-gray-300 hover:bg-gray-200 dark:hover:bg-gray-800 hover:text-gray-900 dark:hover:text-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
                                    role="menuitem"
                                >
                                    <p>{languageData.no_results || defaultLangData.no_results}...</p>
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};